import "@mui/lab/themeAugmentation";
import { createTheme as createMuiTheme, ThemeOptions } from "@mui/material/styles";

import breakpoints from "./breakpoints";
import components from "./components";
import shadows from "./shadows";
import typography from "./typography";
import variants from "./variants";

const createTheme = (name: string) => {
  let themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  const themeOptions: ThemeOptions = {
    spacing: 4,
    breakpoints,
    typography,
    shadows,
    palette: themeConfig.palette,
    name: themeConfig.name,
    header: themeConfig.header,
    footer: themeConfig.footer,
    sidebar: themeConfig.sidebar,
    components: {
      ...components,
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:last-child td": {
              borderBottom: 0,
            },
          },
        },
      },
    },
  };

  const customOptions = {
    name: themeConfig.name,
    header: themeConfig.header,
    footer: themeConfig.footer,
    sidebar: themeConfig.sidebar,
  };

  return createMuiTheme(themeOptions, customOptions);
};

export default createTheme;
