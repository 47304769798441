import { useEffect, useState } from "react";
import { Box, Typography, Button, Paper, Stack } from "@mui/material";
import styled from "@emotion/styled";
import { ComputerIcon, ArrowRightIcon, SmartPhone01Icon } from "@hugeicons-pro/core-stroke-sharp";
import { HugeiconsIcon } from "@hugeicons/react";
import deviceConfig from "../../config/deviceConfig";

const FullscreenOverlay = styled(Paper)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  zIndex: 9999,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0",
  backgroundColor: "#fff",
}));

const ContentContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  maxWidth: "500px",
  padding: "30px 24px",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "auto",
  maxHeight: "80vh",
  justifyContent: "center",
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "32px",
  marginTop: "10px",
  "& > *": {
    margin: "0 16px",
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  marginBottom: "28px",
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  marginTop: "28px",
  marginBottom: "20px",
  width: "100%",
  maxWidth: "300px",
  display: "flex",
  justifyContent: "center",
}));

const DeviceRedirect = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [ignoreMobileWarning, setIgnoreMobileWarning] = useState(false);

  useEffect(() => {
    // If device detection is disabled in config, skip the check
    if (!deviceConfig.enabled) {
      setIsLoading(false);
      return;
    }

    // Function to detect mobile device
    const checkMobile = () => {
      const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
      const mobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));
      return mobile;
    };

    // Check if user has previously ignored the warning
    const ignored = localStorage.getItem(deviceConfig.storageKey) === "true";
    setIgnoreMobileWarning(ignored);

    setIsMobile(checkMobile());
    setIsLoading(false);
  }, []);

  const handleContinueAnyway = () => {
    localStorage.setItem(deviceConfig.storageKey, "true");
    setIgnoreMobileWarning(true);
  };

  // Skip the warning if disabled in config, loading, not mobile, or ignored
  if (!deviceConfig.enabled || isLoading || !isMobile || ignoreMobileWarning) {
    return <>{children}</>;
  }

  const { messages } = deviceConfig;

  return (
    <FullscreenOverlay elevation={3}>
      <ContentContainer spacing={3}>
        <IconContainer>
          <HugeiconsIcon icon={SmartPhone01Icon} size={40} />
          <HugeiconsIcon icon={ArrowRightIcon} size={40} />
          <HugeiconsIcon icon={ComputerIcon} size={40} />
        </IconContainer>

        <TextContainer>
          <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 2 }}>
            {messages.title}
          </Typography>

          <Typography variant="body1" paragraph sx={{ mb: 2 }}>
            {messages.description}
          </Typography>

          <Typography variant="body1" paragraph>
            {messages.recommendation}
          </Typography>
        </TextContainer>

        <ButtonContainer>
          <Button variant="contained" color="primary" fullWidth onClick={handleContinueAnyway} sx={{ py: 1.75 }}>
            {messages.continueButton}
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </FullscreenOverlay>
  );
};

export default DeviceRedirect;
