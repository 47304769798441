import { CacheProvider } from "@emotion/react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "chart.js/auto";
import LogRocket from "logrocket";
import React from "react";
import { Provider } from "react-redux";

import { AuthProvider } from "../contexts/JWTContext";
import { ThemeProvider } from "../contexts/ThemeContext";
import useTheme from "../hooks/useTheme";
import DeviceRedirect from "../components/devices/DeviceRedirect";
import "../i18n";
import { store } from "../redux/store";
import createTheme from "../theme";
import createEmotionCache from "../utils/createEmotionCache";
import "../vendor/jvectormap.css";
import "../vendor/perfect-scrollbar.css";

const clientSideEmotionCache = createEmotionCache();

if (process.env.LOGROCKET_KEY?.length > 0) LogRocket.init(process.env.LOGROCKET_KEY);

function App({ Component, emotionCache = clientSideEmotionCache, pageProps }) {
  const { theme } = useTheme();

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <CacheProvider value={emotionCache}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiThemeProvider theme={createTheme(theme)}>
            <AuthProvider>
              <DeviceRedirect>{getLayout(<Component {...pageProps} />)}</DeviceRedirect>
            </AuthProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
      </Provider>
    </CacheProvider>
  );
}

const withThemeProvider = (Component) => {
  const AppWithThemeProvider = (props) => {
    return (
      <ThemeProvider>
        <Component {...props} />
      </ThemeProvider>
    );
  };
  AppWithThemeProvider.displayName = "AppWithThemeProvider";
  return AppWithThemeProvider;
};

export default withThemeProvider(App);
