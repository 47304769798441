/**
 * Configuration settings for device detection and redirection
 */
const deviceConfig = {
  /**
   * Whether device detection is enabled
   * Set this to false to temporarily disable the mobile warning for all users
   */
  enabled: true,

  /**
   * The message to show to mobile users
   */
  messages: {
    title: "Utilisation sur ordinateur recommandée",
    description:
      "Notre application est optimisée pour une utilisation sur ordinateur. Certaines fonctionnalités pourraient ne pas être disponibles ou difficiles à utiliser sur un appareil mobile.",
    recommendation: "Pour une meilleure expérience, nous vous recommandons de vous connecter depuis un ordinateur.",
    continueButton: "Continuer quand même",
  },

  /**
   * Local storage key for saving user preferences
   */
  storageKey: "ignoreMobileWarning",
};

export default deviceConfig;
