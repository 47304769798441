import { grey, red } from "@mui/material/colors";
import merge from "deepmerge";

import { THEMES } from "../constants";
import { PaletteOptions } from "@mui/material";

const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#7ea8e5",
  400: "#6395e0",
  500: "#4782da",
  600: "#407ad6",
  700: "#376fd0",
  800: "#2f65cb",
  900: "#2052c2 ",
};

export interface ThemeVariant {
  name: string;
  palette: PaletteOptions;
  header: Record<string, any>;
  footer: Record<string, any>;
  sidebar: Record<string, any>;
}

const defaultVariant: ThemeVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: customBlue[700],
      contrastText: "#FFF",
    },
    secondary: {
      main: customBlue[500],
      contrastText: "#FFF",
    },
    info: {
      main: grey[800],
      contrastText: "#FFF",
    },
    background: {
      default: "#FFF",
      paper: "#FFF",
    },
    // neutral: {
    //   main: "#64748B",
    //   contrastText: "#FFF",
    // },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: "#F7F9FC",
    header: {
      color: grey[200],
      background: "#F7F9FC",
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[200],
      background: "#1E2A38",
      online: {
        background: "#48CCBD",
      },
    },
    badge: {
      color: "#FFF",
      background: customBlue[500],
    },
  },
};

const darkVariant: ThemeVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    primary: {
      main: "#786DE2",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#E98382",
      contrastText: "#FFF",
    },
    success: {
      main: "#48CCBD",
      contrastText: "#FFF",
    },
  },
  header: {
    color: grey[100],
    background: "#1F1F1F",
    search: {
      color: grey[300],
    },
    indicator: {
      background: red[500],
    },
  },
  sidebar: {
    color: grey[300],
    background: "#121212",
    header: {
      color: "#FFF",
      background: "#1F1F1F",
      brand: {
        color: "#786DE2",
      },
    },
    footer: {
      color: grey[300],
      background: "#1F1F1F",
      online: {
        background: "#48CCBD",
      },
    },
  },
});

const lightVariant: ThemeVariant = merge(defaultVariant, {
  name: THEMES.LIGHT,
  palette: {
    primary: {
      main: "#786DE2",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#E98382",
      contrastText: "#FFF",
    },
    success: {
      main: "#48CCBD",
      contrastText: "#FFF",
    },
  },
  header: {
    color: grey[200],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: red[700],
    },
  },
  sidebar: {
    color: grey[800],
    background: "#F7F9FC",
    header: {
      color: "#FFF",
      background: "#F7F9FC",
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: grey[800],
      background: "#edf1f8",
      online: {
        background: "#48CCBD",
      },
    },
  },
});

const variants: ThemeVariant[] = [defaultVariant, darkVariant, lightVariant];

export default variants;
