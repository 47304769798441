import { Components } from "@mui/material/styles";

const components: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      "html, body": {
        overscrollBehavior: "contain",
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 32,
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "hover",
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        "& a": {
          textDecoration: "none",
        },
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: "h6",
      },
    },
    styleOverrides: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
        boxShadow: "none",
        backgroundImage: "none",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: "none",
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        "& .MuiSvgIcon-root": {
          fontSize: 18, // Adjust size if needed
        },
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: "48px", // Set your desired min-height
        // Optionally, add responsive breakpoints if needed:
        "@media (min-width:0px)": {
          minHeight: "48px",
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        width: "100%",
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        backgroundColor: "#F7F9FC",
        // Appliquer l'arrondi uniquement sur les bords supérieurs
        "&:first-of-type": {
          borderTopLeftRadius: "8px",
        },
        "&:last-of-type": {
          borderTopRightRadius: "8px",
        },
      },
    },
  },
};

export default components;
