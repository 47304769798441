import { jwtDecode } from "jwt-decode";
import { createContext, useEffect, useReducer } from "react";

import { getUser } from "../services/userService";
import axiosInstance from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  const initUser = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      if (accessToken && isValidToken(accessToken)) {
        const decoded = jwtDecode(accessToken);
        setSession(accessToken);
        // @ts-ignore
        const user = await getUser(decoded.user._id);
        // @ts-ignore
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: true,
            user,
          },
        });

        return user;
      } else {
        // @ts-ignore
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        return false;
      }
    } catch (err) {
      console.error(err);
      // @ts-ignore
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      return false;
    }
  };

  useEffect(() => {
    const initialize = async () => {
      await initUser();
    };

    initialize();
  }, []);

  const signIn = async (email, password) => {
    const body = {
      query: `#graphql
      query($email: String, $password: String) {
        auth(email: $email, password: $password) {
          token
          user {
            _id
            email
            role
            company
            manager
            admin
          }
        }
      }`,
      variables: {
        email,
        password,
      },
    };

    const response = await axiosInstance({ method: "POST", data: body });
    const data = response.data.data;

    if (response.data.errors) {
      throw new Error(response.data.errors[0].message);
    }

    if (data.auth && data.auth.token) {
      const user = data.auth.user;
      const decoded = jwtDecode(data.auth.token);

      // @ts-ignore
      if (decoded.user && decoded.user._id === user._id) {
        setSession(data.auth.token, user._id);
        // @ts-ignore
        dispatch({
          type: SIGN_IN,
          payload: { user },
        });

        return await initUser();
      } else {
        return false;
      }
    }

    return false;
  };

  const signOut = async () => {
    setSession(null);
    // @ts-ignore
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (firstname, lastname, email, password, mobilephone) => {
    setSession(null);
    const body = {
      query: `#graphql
      mutation CreateGuest($firstname: String, $lastname: String, $email: String, $password: String, $mobilephone: String) {
        createGuest(firstname: $firstname, lastname: $lastname, email: $email, password: $password, mobilephone: $mobilephone) {
          _id
        }
      }`,
      variables: {
        firstname,
        lastname,
        email,
        password,
        mobilephone,
      },
    };

    const response = await axiosInstance({ method: "POST", data: body });
    const data = response.data.data;

    if (response.data.errors) {
      if (response.data.errors[0].message.substring(0, 6) === "E11000") {
        throw new Error("Vous avez déjà un compte, veuillez vous connecter");
      }
      throw new Error(response.data.errors[0].message);
    }

    if (data.createGuest?._id) {
      return signIn(email, password);
    }

    throw new Error("Une erreur est survenue lors de la création de votre compte");
  };

  const resetPassword = (email) => console.log(email);

  const refreshUser = async () => {
    await initUser();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
        refreshUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
