import {
  ContactBookIcon,
  File02Icon,
  FileBookmarkIcon,
  LighthouseIcon,
  Task01Icon,
  UserSquareIcon,
} from "@hugeicons-pro/core-stroke-sharp";
import { HugeiconsIcon } from "@hugeicons/react";

// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const COLORS = {
  WALLET_DEFAULT: "#48CCBD",
  WALLET_UNIVERS: "#E98382",
  WALLET_DEFAULT_LIGHT: "rgba(72,204,189,0.2)",
  WALLET_UNIVERS_LIGHT: "rgba(233, 131, 130, .2)",
  E: "#48CCBD",
  S: "#E98382",
  G: "#786DE2",
  CLIENT_IDENTITY: "rgba(0,0,0,.6)",
  CLIENT_IDENTITY_LIGHT: "rgba(0,0,0,.3)",
  CLIENT_MISSION: "rgba(120, 109, 226, 1)",
  CLIENT_MISSION_LIGHT: "rgba(120, 109, 226, .2)",
  CLIENT_COLLECT: "rgba(199, 117, 181,1.0)",
  CLIENT_COLLECT_LIGHT: "rgba(199, 117, 181,0.2)",
  CLIENT_ESG: "rgba(72,204,189,1.0)",
  CLIENT_ESG_LIGHT: "rgba(72,204,189,0.2)",
  CLIENT_RISK: "rgba(233, 131, 130, 1)",
  CLIENT_RISK_LIGHT: "rgba(233, 131, 130, .2)",
  CLIENT_LAB: "rgba(242, 209, 93, 1)",
  CLIENT_LAB_LIGHT: "rgba(242, 209, 93, .2)",
};

// Shares
export const SECTOR = {
  UNKNOWN: "UNKNOWN",
  REAL_ESTATE: "REAL_ESTATE",
  UTILITIES: "UTILITIES",
  TECHNOLOGY: "TECHNOLOGY",
  HEALTH_CARE: "HEALTH_CARE",
  CONSUMER_DISCRETIONARY: "CONSUMER_DISCRETIONARY",
  CONSUMER_STAPLES: "CONSUMER_STAPLES",
  MATERIALS: "MATERIALS",
  ENERGY: "ENERGY",
  FINANCIALS: "FINANCIALS",
  COMMUNICATIONS: "COMMUNICATIONS",
  INDUSTRIALS: "INDUSTRIALS",
  GOVERNMENT: "GOVERNMENT",
};

// Roles
export const ROLE = {
  ADMIN: "ADMIN", // super admin only for Majors team
  ADMIN_OFA: "ADMIN_OFA", // super admin only for Majors team
  ADMIN_OA: "ADMIN_OA", // super admin only for Majors team
  ESG_ADMIN: "ESG_ADMIN", // The client company administrator
  ESG_MANAGER: "ESG_MANAGER", // The manager of users
  ESG_AGENT: "ESG_AGENT", // The agent using the application
  ESG_CLIENT: "ESG_CLIENT", // The clients with no access yet
  GUEST: "GUEST", // For user registration before account validation
  INSURANCE: "INSURANCE", // For insurance form access
};

export const ROLE_COLOR = {
  ADMIN: "error", // super admin only for Majors team
  ESG_ADMIN: "error", // The client company administrator
  ESG_MANAGER: "secondary", // The manager of users
  ESG_AGENT: "primary", // The agent using the application
  ESG_CLIENT: "default", // The clients with no access yet
  GUEST: "neutral", // For user registration before account validation
  INSURANCE: "warning", // For insurance form access
};

export const PLAN = {
  ESG_MANAGER: "ESG_MANAGER",
  OPALHE_PREMIUM: "OPALHE_PREMIUM",
  OPALHE_EXPERT: "OPALHE_EXPERT",
};

export const PLAN_PRICE = {
  ESG_MANAGER: 99,
  OPALHE_PREMIUM: 99,
  OPALHE_EXPERT: 149,
};

export const IMPORT_TYPE = {
  CLIENT_XLS: "CLIENT_XLS",
  METRIC_XLS: "METRIC_XLS",
};

export const DOC_TYPE_COLOR = {
  CNI: "rgba(0,0,0,.2)",
  DER: "rgba(120, 109, 226, .4)",
  RECUEIL: "rgba(199, 117, 181,0.4)",
  LETTRE_DE_MISSION: "rgba(72,204,189,0.4)",
  RAPPORT_D_ADEQUATION: "rgba(233, 131, 130, .4)",
  LCBFT: "rgba(242, 209, 93, .4)",
};

export const DOC_TYPE_ICON = {
  CNI: <HugeiconsIcon icon={UserSquareIcon} />,
  DER: <HugeiconsIcon icon={ContactBookIcon} />,
  RECUEIL: <HugeiconsIcon icon={Task01Icon} />,
  LETTRE_DE_MISSION: <HugeiconsIcon icon={File02Icon} />,
  RAPPORT_D_ADEQUATION: <HugeiconsIcon icon={FileBookmarkIcon} />,
  LCBFT: <HugeiconsIcon icon={LighthouseIcon} />,
};

export const DOC_TYPE = {
  ALLOCATION_CAF: "ALLOCATION_CAF",
  ATTESTATION_SUR_HONNEUR_DISPENSE_PFO: "ATTESTATION_SUR_HONNEUR_DISPENSE_PFO",
  ATTESTATION_ORIAS: "ATTESTATION_ORIAS",
  ATTESTATION_RCP: "ATTESTATION_RCP",
  ATTESTATION_GARANTIE_FINANCIERE: "ATTESTATION_GARANTIE_FINANCIERE",
  ATTESTATION_FORMATION: "ATTESTATION_FORMATION",
  BENEFICIAIRES_EFFECTIFS: "BENEFICIAIRES_EFFECTIFS",
  ATTESTATION_RAT_ASS_PRO: "ATTESTATION_RAT_ASS_PRO",
  AVIS_IMPOSITION: "AVIS_IMPOSITION",
  AVIS_SIRENE: "AVIS_SIRENE",
  AVIS_TIERS_DETENTEUR: "AVIS_TIERS_DETENTEUR",
  CARTE_ELECTORALE: "CARTE_ELECTORALE",
  CARTE_INVALIDITE: "CARTE_INVALIDITE",
  CARTE_RESIDENT_ETRANGER: "CARTE_RESIDENT_ETRANGER",
  CARTE_SEJOUR: "CARTE_SEJOUR",
  CERTIFICAT_DECES: "CERTIFICAT_DECES",
  CNI: "CNI",
  RIB: "RIB",
  CONTRAT_MARIAGE: "CONTRAT_MARIAGE",
  CONVENTION: "CONVENTION",
  CONVENTION_PACS: "CONVENTION_PACS",
  COPIE_JUGEMENT_AFFECTATION_AUTORITE_PARENTALE: "COPIE_JUGEMENT_AFFECTATION_AUTORITE_PARENTALE",
  COPIE_JUGEMENT_MISE_SOUS_PROTECTION: "COPIE_JUGEMENT_MISE_SOUS_PROTECTION",
  COPIE_PARUTION_DECRET_JO: "COPIE_PARUTION_DECRET_JO",
  COPIE_RECEPISSE_DECLARATION_PREFECTURE: "COPIE_RECEPISSE_DECLARATION_PREFECTURE",
  COPIE_STATUTS_CERTIFIES_CONFORMES: "COPIE_STATUTS_CERTIFIES_CONFORMES",
  EXTRAIT_ACTE_MARIAGE: "EXTRAIT_ACTE_MARIAGE",
  EXTRAIT_ACTE_NAISSANCE: "EXTRAIT_ACTE_NAISSANCE",
  FICHE_CONNAISSANCE_CLIENT: "FICHE_CONNAISSANCE_CLIENT",
  FORMULAIRE_IRS: "FORMULAIRE_IRS",
  JUSTIFICATIF_DOMICILE: "JUSTIFICATIF_DOMICILE",
  KBIS: "KBIS",
  LIVRET_FAMILLE: "LIVRET_FAMILLE",
  PASSEPORT: "PASSEPORT",
  PERMIS_BATEAU: "PERMIS_BATEAU",
  PERMIS_CHASSE: "PERMIS_CHASSE",
  PERMIS_CONDUIRE: "PERMIS_CONDUIRE",
  POUVOIR: "POUVOIR",
  QUESTIONNAIRE_FATCA: "QUESTIONNAIRE_FATCA",
  // Insurance
  DEVIS: "DEVIS",
  CONDITIONS_PARTICULIERES: "CONDITIONS_PARTICULIERES",
  LETTRE_RESILIATION: "LETTRE_RESILIATION",
  ATTESTATION: "ATTESTATION",
  MANDAT_SEPA: "MANDAT_SEPA",
  DER: "DER",
  RECUEIL: "RECUEIL",
  LETTRE_DE_MISSION: "LETTRE_DE_MISSION",
  RAPPORT_D_ADEQUATION: "RAPPORT_D_ADEQUATION",
  LCBFT: "LCBFT",
  TRACFIN: "TRACFIN",
  DIC: "DIC",
  ANNEXE: "ANNEXE",
};

export const DOC_ORDER = ["CNI", "DER", "RECUEIL", "LETTRE_DE_MISSION", "RAPPORT_D_ADEQUATION", "LCBFT"];
